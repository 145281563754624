import { CommandeData } from '@innedit/innedit';
import { CommandeType, DocumentType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '../../../containers/Espace/props';
import capitalize from '../../../utils/capitalize';
import ListItemDate from '../../ListItemDate';
import Tag, { TagStateProps } from '../../Tag';
import ListItem from './index';

const labelPaymentStatus = (paymentStatus?: string): string | undefined => {
  let statusLabel;
  switch (paymentStatus) {
    case 'canceled': {
      statusLabel = 'Paiement annulé';
      break;
    }

    case 'partial_refunded': {
      statusLabel = 'Paiement partiellement remboursé';
      break;
    }

    case 'refunded': {
      statusLabel = 'Paiement remboursé';
      break;
    }

    case 'requires_capture': {
      statusLabel = 'En attente de validation';
      break;
    }

    case 'succeeded': {
      statusLabel = 'Paiement validé';
      break;
    }

    default:
      statusLabel = paymentStatus;
  }

  return statusLabel;
};

const statePaymentStatus = (paymentStatus?: string): TagStateProps => {
  let state: TagStateProps;
  switch (paymentStatus) {
    case 'canceled': {
      state = 'info';
      break;
    }

    case 'partial_refunded': {
      state = 'warning';
      break;
    }

    case 'refunded': {
      state = 'info';
      break;
    }

    case 'requires_capture': {
      state = 'danger';
      break;
    }

    case 'succeeded': {
      state = 'success';
      break;
    }

    default:
      state = 'standard';
  }

  return state;
};

const ListItemCommande: FC<ListItemProps<CommandeType>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<CommandeType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  const date = doc.createdAt;

  const name = [];
  if (doc.contactCommercialName) {
    name.push(doc.contactCommercialName);
  } else {
    if (doc.contactFirstName) {
      name.push(doc.contactFirstName);
    }
    if (doc.contactLastName) {
      name.push(doc.contactLastName);
    }
  }

  if (doc.contactZip || doc.contactCity) {
    const city = [];
    if (doc.contactZip) {
      city.push(doc.contactZip);
    }
    if (doc.contactCity) {
      city.push(capitalize(doc.contactCity.trim()));
    }
    name.push(`(${city.join(' ')})`);
  }

  const total =
    CommandeData.calculateProduitsAmount(doc.produits) +
    ('carrier' === doc.deliveryMethod ? doc.deliveryAmount : 0);

  return (
    <ListItem displayActionId={false} doc={doc} index={index} onClick={onClick}>
      <ListItemDate date={date} />
      <div className="flex-auto flex flex-col mx-3 space-y-1">
        <strong className="whitespace-nowrap">{name.join(' ')}</strong>

        {doc.paymentStatus && (
          <div className="flex space-x-3">
            <Tag state={statePaymentStatus(doc.paymentStatus)}>
              {labelPaymentStatus(doc.paymentStatus)}
            </Tag>
          </div>
        )}
      </div>
      <div className="flex items-end flex-col">
        <div className="total">
          <span className="montant">{total} €</span>
        </div>
        {'carrier' === doc.deliveryMethod && (
          <div className="delivery_cost text-sm">
            Livraison : {doc.deliveryAmount}€
          </div>
        )}

        {doc.deliveryMethod && 'store' === doc.deliveryMethod && (
          <div className="delivery_store text-sm whitespace-nowrap">
            Retrait au magasin
          </div>
        )}
      </div>
    </ListItem>
  );
};

export default ListItemCommande;

import { CommandeData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import compact from 'lodash/compact';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Commande from '../../../../components/List/Item/Commande';
import CMSView from '../../../../components/View';
import List from '../../../../containers/Espace/List';
import commande from '../../../../params/commande.json';
import TemplateEspace from '../../../../templates/espace';
import { parse } from '../../../../utils/queryString';
import requireEspace, { EspaceProps } from '../../../../utils/requireEspace';
import { UserProps } from '../../../../utils/requireUser';

const PageBonsCommande: FC<
  PageProps & EspaceProps & UserProps & { pageContext: { tab?: number } }
> = ({
  espace,
  location,
  user,
  pageContext: { tab },
  params: { espaceId },
}) => {
  const { t } = useTranslation();
  const params = parse(location.search);

  const [wheres, setWheres] = useState<{ [key: string]: any }>({});

  const paramStringIsArchived =
    params.isArchived && Array.isArray(params.isArchived)
      ? params.isArchived.join(',')
      : params.isArchived;

  const paramsStringPaymentStatus =
    params.paymentStatus && Array.isArray(params.paymentStatus)
      ? params.paymentStatus.join(',')
      : params.paymentStatus;

  // const paramsStringType =
  //   params.type && Array.isArray(params.type)
  //     ? params.type.join(',')
  //     : params.type;

  useEffect(() => {
    setWheres(oldWheres => {
      const newWheres: { [key: string]: any } = { ...oldWheres };

      if (params.isArchived) {
        newWheres.isArchived = {
          operator: 'in',
          value: params.isArchived,
        };
      } else {
        delete newWheres.isArchived;
      }

      return newWheres;
    });
  }, [paramStringIsArchived]);

  useEffect(() => {
    setWheres(oldWheres => {
      const newWheres: { [key: string]: any } = { ...oldWheres };

      if (params.paymentStatus) {
        newWheres.paymentStatus = {
          operator: 'in',
          value: params.paymentStatus,
        };
      } else {
        delete newWheres.paymentStatus;
      }

      return newWheres;
    });
  }, [paramsStringPaymentStatus]);

  // useEffect(() => {
  //   setWheres(oldWheres => {
  //     const newWheres: { [key: string]: any } = { ...oldWheres };
  //
  //     if (params.type) {
  //       newWheres.type = {
  //         operator: 'in',
  //         value: params.type,
  //       };
  //     } else {
  //       delete newWheres.type;
  //     }
  //
  //     return newWheres;
  //   });
  // }, [paramsStringType]);

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List
          displaySearch
          filters={compact([
            {
              libelle: 'Paiement',
              multiple: true,
              name: 'paymentStatus',
              options: [
                {
                  label: 'A confirmer',
                  value: 'requires_capture',
                },
                {
                  label: 'En attente',
                  value: 'requires_payment_method',
                },
                {
                  label: 'Validé',
                  value: 'succeeded',
                },
                {
                  label: 'Annulé',
                  value: 'canceled',
                },
              ],
            },
            {
              libelle: 'Archivée',
              multiple: true,
              name: 'isArchived',
              options: [
                {
                  label: 'Oui',
                  value: true,
                },
                {
                  label: 'Non',
                  value: false,
                },
              ],
            },
            // {
            //   libelle: 'Type',
            //   multiple: true,
            //   name: 'type',
            //   options: [
            //     {
            //       label: 'Commande',
            //       value: 'order',
            //     },
            //     {
            //       label: 'Panier',
            //       value: 'cart',
            //     },
            //     {
            //       label: 'Devis',
            //       value: 'quotation',
            //     },
            //   ],
            // },
          ])}
          itemList={Commande}
          itemPathnamePrefix={`/espaces/${espaceId}/bons-commande/`}
          model={
            new CommandeData({
              espaceId,
              wheres,
              orderDirection: 'desc',
              orderField: 'createdAt',
              params: commande,
            })
          }
          search={location.search}
          tabIndex={tab}
          title={t('bons-commande.title')}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageBonsCommande);

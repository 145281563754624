import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import requireEspace, { EspaceProps } from '../../../../utils/requireEspace';
import { UserProps } from '../../../../utils/requireUser';
import BonsCommande from './index';

const PageBonsCommandeBrouillon: FC<
  PageProps & EspaceProps & UserProps
> = props => <BonsCommande {...props} pageContext={{ tab: 3 }} />;

export default requireEspace(PageBonsCommandeBrouillon);
